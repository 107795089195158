.nav-sticky
  position: fixed !important
  top: calc(var(--navbarHeight))
  left: 0
  width: 100%
  z-index: 3
  padding: .25rem
  background-color: var(--surface-300)
  .btn
    margin-right: .25rem
.navbar
  height: var(--navbarHeight)
  font-size: 1rem
  padding: 0
  background: var(--navBg)
  color: var(--navColor)
  .navbar-brand
    margin: 0 .5rem 0 .5rem
    height: 5rem
    border-bottom: 0
    img
      margin: .5rem 0 .5rem 0
      height: 4rem
      // max-width: 10rem
      @media (max-width: 767px)
        max-width: 6rem
    .navbar-nav
      margin: auto
.fixed-nav-top
  z-index: 1000
  background-color: var(--navBg)
  color: var(--navColor)
  box-shadow: 0 2px 2px rgba(45,45,45,.05),0 4px 4px rgba(49,49,49,.05),0 8px 8px rgba(42,42,42,.05),0 16px 16px rgba(32,32,32,.05),0 32px 32px rgba(49,49,49,.05),0 64px 64px rgba(35,35,35,.05)
.fixed-nav
  position: fixed
  z-index: 1000
  right: 0
  left: 0
  height: var(--navbarHeight)
  background-color: var(--navBg)
  color: var(--navColor)
  box-shadow: 0 2px 2px rgba(45,45,45,.05),0 4px 4px rgba(49,49,49,.05),0 8px 8px rgba(42,42,42,.05),0 16px 16px rgba(32,32,32,.05),0 32px 32px rgba(49,49,49,.05),0 64px 64px rgba(35,35,35,.05)
  @media (max-width: 767px)
    bottom: 0
    height: 3.5rem
  @media (min-width: 768px)
    top: 0
  .p-jc-start, .p-jc-end
    height: calc(var(--navbarHeight)/2)
