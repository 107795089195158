@import "../../node_modules/bootstrap/scss/functions"
@import "../../node_modules/bootstrap/scss/variables"
@import "../../node_modules/bootstrap/scss/mixins/breakpoints"
@import "../../node_modules/ngx-spinner/animations/ball-scale-pulse.css"

// custom prime from fluent
@import "../assets/yes4web/prime-theme.css"

// for primeng calendar
//@import "../../node_modules/@fullcalendar/daygrid"
//@import "../../node_modules/@fullcalendar/daygrid/main.min.css"
//@import "../../node_modules/@fullcalendar/timegrid/main.min.css"
//@import "../../node_modules/@fullcalendar/list/main.min.css"
//@import "../../node_modules/@fullcalendar/resource-timeline/main.min.css"

@import "./alerts"
@import "./badges"
@import "./buttons"
@import "./cards"
@import "./colors"
@import "./dialogs"
@import "./dropdowns"
@import "./fontawesome"
@import "./forms"
@import "./grids"
@import "./helpers"
@import "./navs"
@import "./typography"

html
  scroll-behavior: smooth
  touch-action: manipulation
  //position: fixed
  //width: 100%

.w-full
  width: 100%

.y-page
  width: 100%
  height: calc(var(--vh) - var(--navbarHeight) - 1px)
  padding: .5rem
  overflow-x: hidden
  overflow-y: auto
  @media (min-width: 768px)
    margin-top: var(--navbarHeight)
  @media (max-width: 767px)
    height: calc(var(--vh) - 3.5rem)
    margin-bottom: 3.5rem

.fullscreen .y-page
  margin-top: var(--navbarHeight)
  height: auto
  @media (max-width: 767px)
    margin-bottom: 0

.y-dialog-full
  max-height: 100% !important
  width: 100vw

.page
  margin: 0
  margin-top: calc(var(--navbarHeight) + 3rem)
  min-height: calc(var(--vh) - var(--navbarHeight) - 4rem)
  background-color: white
  @media (max-width: 767px)
    margin-top: 0
    margin-bottom: calc(var(--navbarHeight))

.page-no-buttons
  margin: 0
  z-index: 1040
  margin-top: calc(var(--navbarHeight)) !important
  min-height: calc(var(--vh) - var(--navbarHeight) - 24px)
  background-color: white
  @media (max-width: 767px)
    margin-top: 0 !important
    margin-bottom: 3.5rem !important
    min-height: calc(100vh - 3.5rem)

//@media (max-width: 767px)
.page-short
  margin-top: calc(calc(var(--navbarHeight) + 3.5rem) / 1.7) !important
  min-height: calc(100vh - calc(calc(var(--navbarHeight) + 3.5rem) / 1.7))
  padding: 20px !important
  background-color: white

.page-no-buttons-short
  margin-top: calc(var(--navbarHeight) / 1.7) !important
  min-height: calc(100vh - calc(var(--navbarHeight) / 1.7))
  background-color: white

.cursor
  cursor: pointer

// ng2-tree
.node-value span
  font-size: .75rem

html, body
  height: 100%
  background-color: white
  overflow-x: hidden
  font-family: var(--fontFamilySansSerif)
  font-size: 16px
  padding-top: env(safe-area-inset-top, 0px)
a:hover
  text-decoration-line: none

.table td, .table th
  font-size: .75rem
  padding: 0.1rem .75rem
  @media (max-width: 767px)
    padding: 0.1rem .1rem

input,
textarea,
label,
.col-form-label-sm,
.form-control-sm,
select,
optgroup,
textarea,
.page-item,
.pagination-link,
.small,
.dropdown-item
  font-size: .75rem

.font
  font-size: .85rem

.box
  border: 3px solid transparent
.line-through
  text-decoration: line-through

.td-150
  width: 150px
  min-width: 150px

.pc .outline.outline--cart
  line-height: 38px
  line-height: 2.375rem

@media only screen and (max-width: 767px)
  .outline
    margin-top: 5px
    width: 30px
    width: 1.875rem
    height: 30px
    height: 1.875rem
    line-height: 30px
    line-height: 1.875rem
  .pc .outline.outline--cart
    line-height: 39px
    line-height: 2.438rem

.outline > [class^="icon2-"]::before
  font-size: 19px
  font-size: 1.188rem
  width: 20px
  width: 1.25rem
  height: 20px
  height: 1.25rem

.pc .outline > [class^="icon2-"].icon2-user-filled-minor::before
  width: 18px
  width: 1.125rem

.mac .outline > [class^="icon2-"].icon2-user-filled-minor::before
  width: 20px
  width: 1.25rem

@-moz-document url-prefix()
  .mac .outline > [class^="icon2-"].icon2-user-filled-minor::before
    width: 18px
    width: 1.125rem
@media (max-width: 767px)
  .outline
    width: 2.25rem
    height: 2.25rem
    line-height: 2.25rem
    color: var(--dark)
@media only screen and (max-width: 760px)
  // .outline > [class^="icon2-"]::before
  //   width: 20px
  //   width: 1.25rem
  //   height: 20px
  //   height: 1.25rem
  .profile-nav .profile-actions .user-image-container
    vertical-align: middle
    display: inline-block
.products, .products-home
  background: white
.fs75rem
  font-size: .75rem !important
.fa100rem
  font-size: 1rem !important
.fa125rem
  font-size: 1.25rem !important
.custom-select-sm
  font-size: .75rem !important
@media print
  .d-print-none
    display: none !important

.notification
  padding-left: 10px

pre
  padding: 10px
  background: var(--secondary)
  color: white
  font-size: .75rem

h6
  font-size: .75rem
.dropdown-item p
  margin: 0.125rem 0
#toTop
  display: none
  position: fixed
  bottom: 20px
  right: 30px
  z-index: 99
  border: none
  outline: none
  color: var(--dark)
  cursor: pointer
  font-size: 18px
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 100px 1000px 0 rgba(0, 0, 0, 0.4)
.default
  cursor: default !important

.notity-generic-modal
  .mat-dialog-container
    padding: 0
    min-width: 300px

.yes4mkt
  background: black
  color: white

.VISIT
  .cal-event-title
    color: var(--light)

.flexCenterWidth
  width: var(--flexCenterWidth)
  heigth: var(--containerHeight)
  // margin: auto 0 auto auto

.ml-15r
  margin-left: 15rem

/** ngPrime **/
.fixed-paginator, .paginator-fixed
  .ui-paginator-bottom, .ui-paginator
    position: fixed
    bottom: 0
    left: 0
    width: 100%

.table-no-border
  .ui-table
    tr
      border-bottom: 1px solid #999
    tr:last-child
      border-bottom: 0
    td, th
      border: 0 !important
    th
      background-color: transparent !important
      input[type=text]
        border: 1px solid #999
        width: 98%

  .ui-dataview-header
    display: none
  .ui-dataview-content
    border: 0 !important
    padding: 0 !important

/* Column Priorities */
@media only all
  th.ui-p-6,
  td.ui-p-6,
  th.ui-p-5,
  td.ui-p-5,
  th.ui-p-4,
  td.ui-p-4,
  th.ui-p-3,
  td.ui-p-3,
  th.ui-p-2,
  td.ui-p-2,
  th.ui-p-1,
  td.ui-p-1
    display: none

/* Show priority 1 at 320px (20em x 16px) */
@media screen and (min-width: 20em)
  th.ui-p-1,
  td.ui-p-1
    display: table-cell

/* Show priority 2 at 480px (30em x 16px) */
@media screen and (min-width: 30em)
  th.ui-p-2,
  td.ui-p-2
    display: table-cell

/* Show priority 3 at 640px (40em x 16px) */
@media screen and (min-width: 40em)
  th.ui-p-3,
  td.ui-p-3
    display: table-cell

/* Show priority 4 at 800px (50em x 16px) */
@media screen and (min-width: 50em)
  th.ui-p-4,
  td.ui-p-4
    display: table-cell

/* Show priority 5 at 960px (60em x 16px) */
@media screen and (min-width: 60em)
  th.ui-p-5,
  td.ui-p-5
    display: table-cell

/* Show priority 6 at 1,120px (70em x 16px) */
@media screen and (min-width: 70em)
  th.ui-p-6,
  td.ui-p-6
    display: table-cell

.debug-box
  position: relative
  border: 2px solid red
  legend
    padding: 0 .25rem
    background-color: red
    color: #fff
    font-size: .75rem
    position: absolute
    top: 0
    left: 0
    z-index: 10000
    display: inline-block
    width: auto

.clean-table
  .ui-table-wrapper, .p-datatable-wrapper
    table
      .ui-table-tbody, .ui-table-thead, .p-datatable-tbody, .p-datatable-thead
        tr > td, tr > th
        border: 0
        border-bottom: 1px solid #e0e0e0
      .ui-table-thead, .p-datatable-thead
        tr:first-child
          th
            border-top: 1px solid #e0e0e0

.required-data-modal
  .ui-dialog-content
    padding: 0 !important

.cashback-detail, .invoice-beta-component
  .ui-inputtext, .p-inputtext
    width: 100% !important
    font-size: .75rem

.no-border
  border: 0 !important
  background-color: var(--light) !important
.no-border:focus
  outline: none !important
  outline-width: 0 !important
.form-shadow, .form-shadow.p-focus
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important

@media (max-width: 1199px)
  .hidden-lg
    display: none !important
@media (max-width: 991px)
  .hidden-md
    display: none !important
@media (max-width: 767px)
  .hidden-sm
    display: none !important

@media (min-width: 1200px)
  .show-xl
    display: none !important
@media (min-width: 992px)
  .show-lg
    display: none !important
@media (min-width: 768px)
  .show-md
    display: none !important
@media (min-width: 576px)
  .show-sm
    display: none !important

div:focus, button:focus,
button:focus, i:focus, span:focus,
.btn:focus, .fa:focus, *:focus
  outline-width: 0
// textarea:focus,
// textarea.form-control:focus,
// input.form-control:focus,
// input[type=text]:focus,
// input[type=password]:focus,
// input[type=email]:focus,
// input[type=number]:focus,
// [type=text].form-control:focus,
// [type=password].form-control:focus,
// [type=email].form-control:focus,
// [type=tel].form-control:focus,
// [contenteditable].form-control:focus,
// button:focus, i:focus, span:focus,
// .btn:focus, .fa:focus, *:focus
//   box-shadow: none !important

.btn-nav
  font-size: .75rem

// Configuração nenerica para dynamic modal do PrimeNG
// Cria um modal com 95% de largira em movel e 400px em desktop
.modal-sm-full-md-400px
  width: 95%
  max-width: 400px

.z-index-top
  z-index: 100000

.p-dropdown .p-dropdown-label
  border: none !important

.challenge
  .p-md-6, .p-lg-5
    padding: 0.5rem !important
  p, textarea, input
    font-size: 16px
  .wrapper
    display: grid
    flex-wrap: wrap
    grid-gap: .25rem
    grid-template-columns: repeat(2, 1fr)
    grid-template-rows: 1fr
    @media (max-width: 575px)
      grid-template-columns: repeat(1, 1fr)
  hr
    margin: 2rem 0
  .form-control-sm
    padding: 1rem
  .card
    //cursor: pointer
    color: var(--white)
    border: none
    border-radius: 0
    width: 100%
    height: 100%
    .phrase
      font-size: 1rem
    .team
      font-size: .75rem
    .voted
      color: rgba(255, 255, 255, .2)

  .filter
    cursor: pointer
    font-size: 16px

.help-tip
  .p-tooltip-text
    color: #fff
    background-color: #333 !important
.y-tooltip
  font-size: .85rem
  display: inline-block
  margin-left: .35rem

@media (min-width: 761px)
  *::-webkit-scrollbar-thumb
    height: 56px
    border-radius: 8px
    border: 1px solid transparent
    background-clip: content-box
    background-color: hsl(0,0%,67%)

  *::-webkit-scrollbar
    width: 9px
    background-color: transparent
